/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useKeyboardControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import {
  playAudio,
  useGameStateStore,
} from "../../stateManagers/GameStateManager";

export default function Ninja(props) {
  const group = useRef();
  const playingAudio = useRef();
  const { jumpAudio, walkAudio, runAudio } = useGameStateStore();
  const [subscribeKeys, getKeys] = useKeyboardControls();
  const { forward, backward, leftward, rightward, jump, run } = getKeys();
  const { nodes, materials, animations } = useGLTF(
    "./models/characters/Ninja.glb"
  );
  for (const material in materials) {
    materials[material].metalness = -2;
    materials[material].roughness = 1;
  }

  useEffect(() => {
    const unSubscribeJump = subscribeKeys(
      (state) => state.jump,
      (value) => {
        if (value) {
          jumpAudio.play();
          walkAudio.pause();
        }
      }
    );

    // const unSubscribeRun = subscribeKeys(
    //   (state) => state.run,
    //   (value) => {
    //     if (value && !jump) {
    //       walkAudio.pause();
    //       runAudio.play();
    //     } else {
    //       walkAudio.play();
    //       runAudio.pause();
    //     }
    //   }
    // );

    // const unSubscribeForward = subscribeKeys(
    //   (state) => state.forward,
    //   (value) => {
    //     if (value && !jump) {
    //       walkAudio.play();
    //     } else {
    //       walkAudio.pause();
    //     }
    //   }
    // );

    // const unSubscribeBackward = subscribeKeys(
    //   (state) => state.backward,
    //   (value) => {
    //     if (value && !jump) {
    //       walkAudio.play();
    //     } else {
    //       walkAudio.pause();
    //     }
    //   }
    // );

    // const unSubscribeLeftward = subscribeKeys(
    //   (state) => state.leftward,
    //   (value) => {
    //     if (value && !jump) {
    //       walkAudio.play();
    //     } else {
    //       walkAudio.pause();
    //     }
    //   }
    // );

    // const unSubscribeRightward = subscribeKeys(
    //   (state) => state.rightward,
    //   (value) => {
    //     if (value && !jump) {
    //       walkAudio.play();
    //     } else {
    //       walkAudio.pause();
    //     }
    //   }
    // );

    return () => {
      unSubscribeJump();
      // unSubscribeRun();
      // unSubscribeForward();
      // unSubscribeBackward();
      // unSubscribeLeftward();
      // unSubscribeRightward();
    };
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" scale={0.5} position-y={-0.7}>
        <group name="CharacterArmature">
          <primitive object={nodes.Root} />
          <group name="Ninja">
            <skinnedMesh
              name="Cube092"
              geometry={nodes.Cube092.geometry}
              material={materials.Ninja_Main}
              skeleton={nodes.Cube092.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_1"
              geometry={nodes.Cube092_1.geometry}
              material={materials.Ninja_Secondary}
              skeleton={nodes.Cube092_1.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_2"
              geometry={nodes.Cube092_2.geometry}
              material={materials.Belt}
              skeleton={nodes.Cube092_2.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_3"
              geometry={nodes.Cube092_3.geometry}
              material={materials.Gold}
              skeleton={nodes.Cube092_3.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_4"
              geometry={nodes.Cube092_4.geometry}
              material={materials.Eye_Black}
              skeleton={nodes.Cube092_4.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_5"
              geometry={nodes.Cube092_5.geometry}
              material={materials.Eye_White}
              skeleton={nodes.Cube092_5.skeleton}
              receiveShadow
              castShadow
            />
            <skinnedMesh
              name="Cube092_6"
              geometry={nodes.Cube092_6.geometry}
              material={materials.Birb_Secondary}
              skeleton={nodes.Cube092_6.skeleton}
              receiveShadow
              castShadow
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/characters/Ninja.glb");
