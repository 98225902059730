import React, { useEffect } from "react";
import "../ui.css";
import { useGameStateStore } from "../../../stateManagers/GameStateManager";

export default function GameStatusModal({ message }) {
  const resetGameStatus = useGameStateStore((state) => state.resetGameStatus);
  return (
    <div className="youWin">
      <h1>{message}</h1>
      <button className="restartButton" onClick={() => resetGameStatus()}>
        {" "}
        Restart{" "}
      </button>
    </div>
  );
}
