/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Catcher.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody, vec3 } from "@react-three/rapier";
import { useGameStateStore } from "../../../stateManagers/GameStateManager";

export function Catcher(props) {
  const group = useRef();
  const decreaseLives = useGameStateStore((state) => state.decreaseLives);
  const { nodes } = useGLTF("./models/maps/scene_1/Catcher.glb");

  const OnCollided = (e) => {
    e.rigidBody.setTranslation(vec3({ X: 0, y: 5, z: 0 }));
    e.rigidBody.setLinvel(vec3({ X: 0, y: 0, z: 0 }));

    decreaseLives();
  };
  return (
    <RigidBody onCollisionEnter={(e) => OnCollided(e)}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="Catcher"
            geometry={nodes.Catcher.geometry}
            position={[67.047, -8.817, -4.877]}
            scale={[103.933, 103.933, 43.11]}
          >
            <meshPhongMaterial color="#ff0000" opacity={0} transparent />
          </mesh>
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./models/maps/scene_1/Catcher.glb");
