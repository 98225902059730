/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Saws.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Saw } from "../../common/Saw";

export function Saws(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/maps/scene_1/Saws.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <Saw
          name="Hazard_Cylinder"
          position={[15.374, 4.865, 0.446]}
          scale={[66.516, 66.516, 84.469]}
          rotationAxis={"z"}
        >
          <mesh
            name="Hazard_Cylinder001"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Cylinder001.geometry}
            material={materials["Black.006"]}
          />
          <mesh
            name="Hazard_Cylinder001_1"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Cylinder001_1.geometry}
            material={materials["Metal.012"]}
          />
          <mesh
            name="Hazard_Cylinder001_2"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Cylinder001_2.geometry}
            material={materials["DarkMetal.008"]}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[32.061, 4.102, -1.125]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[29.816, 4.102, 1.944]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw001.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[55.614, 6.862, -7.849]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw002.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[53.37, 6.862, -4.78]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw003.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[51.319, 6.862, -7.849]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw004.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
        <Saw
          name="Saw"
          position={[49.075, 6.862, -4.78]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          rotationAxis={"y"}
        >
          <mesh
            name="Hazard_Saw"
            castShadow
            receiveShadow
            geometry={nodes.Hazard_Saw005.geometry}
            material={materials["Metal.014"]}
            scale={100}
          />
        </Saw>
      </group>
    </group>
  );
}

useGLTF.preload("./models/maps/scene_1/Saws.glb");
