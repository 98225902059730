/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\StaticAssets.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export function StaticAssets(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/maps/scene_1/StaticAssets.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <RigidBody type="fixed" colliders="trimesh">
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Tower"
            position={[88.339, 3.147, -2.787]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={46.804}
          >
            <mesh
              name="Tower001"
              castShadow
              receiveShadow
              geometry={nodes.Tower001.geometry}
              material={materials["Tower_Dark.001"]}
            />
            <mesh
              name="Tower001_1"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_1.geometry}
              material={materials["Wood.007"]}
            />
            <mesh
              name="Tower001_2"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_2.geometry}
              material={materials["Wood_Light.004"]}
            />
            <mesh
              name="Tower001_3"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_3.geometry}
              material={materials["Black.004"]}
            />
            <mesh
              name="Tower001_4"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_4.geometry}
              material={materials["Tower.001"]}
            />
            <mesh
              name="Tower001_5"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_5.geometry}
              material={materials["Metal.009"]}
            />
            <mesh
              name="Tower001_6"
              castShadow
              receiveShadow
              geometry={nodes.Tower001_6.geometry}
              material={materials["Flag.002"]}
            />
          </group>
          <group
            name="Tower_Door"
            position={[88.924, 4.498, -1.31]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={46.804}
          >
            <mesh
              name="Tower_Door001"
              castShadow
              receiveShadow
              geometry={nodes.Tower_Door001.geometry}
              material={materials["Tower_Dark.001"]}
            />
            <mesh
              name="Tower_Door001_1"
              castShadow
              receiveShadow
              geometry={nodes.Tower_Door001_1.geometry}
              material={materials["Wood.007"]}
            />
            <mesh
              name="Tower_Door001_2"
              castShadow
              receiveShadow
              geometry={nodes.Tower_Door001_2.geometry}
              material={materials["Wood_Light.004"]}
            />
          </group>
          <group
            name="Arrow_Side"
            position={[38.594, 8.878, -2.716]}
            rotation={[-Math.PI / 2, 0, -0.822]}
            scale={88.758}
          >
            <mesh
              name="Arrow_Side001_1"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side001_1.geometry}
              material={materials["Wood.008"]}
            />
            <mesh
              name="Arrow_Side001_2"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side001_2.geometry}
              material={materials["Main_Dark.001"]}
            />
          </group>
          <group
            name="Cannon"
            position={[44.177, 9.403, -2.695]}
            rotation={[-Math.PI / 2, 0, -2.081]}
            scale={42.797}
          >
            <mesh
              name="Cannon001"
              castShadow
              receiveShadow
              geometry={nodes.Cannon001.geometry}
              material={materials["Wood.009"]}
            />
            <mesh
              name="Cannon001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cannon001_1.geometry}
              material={materials["DarkMetal.006"]}
            />
            <mesh
              name="Cannon001_2"
              castShadow
              receiveShadow
              geometry={nodes.Cannon001_2.geometry}
              material={materials["Metal.010"]}
            />
            <mesh
              name="Cannon001_3"
              castShadow
              receiveShadow
              geometry={nodes.Cannon001_3.geometry}
              material={materials["White.004"]}
            />
          </group>
          <group
            name="Cannon_Wheels"
            position={[44.177, 9.403, -2.695]}
            rotation={[-Math.PI / 2, 0, -2.081]}
            scale={42.797}
          >
            <mesh
              name="Cannon_Wheels001"
              castShadow
              receiveShadow
              geometry={nodes.Cannon_Wheels001.geometry}
              material={materials["Wood.009"]}
            />
            <mesh
              name="Cannon_Wheels001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cannon_Wheels001_1.geometry}
              material={materials["Wood_Light.005"]}
            />
          </group>
          <group
            name="Fence_Middle"
            position={[39.021, 8.859, 2.874]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={88.758}
          >
            <mesh
              name="Fence_Middle001"
              castShadow
              receiveShadow
              geometry={nodes.Fence_Middle001.geometry}
              material={materials["Wood_Light.007"]}
            />
            <mesh
              name="Fence_Middle001_1"
              castShadow
              receiveShadow
              geometry={nodes.Fence_Middle001_1.geometry}
              material={materials["Wood.012"]}
            />
          </group>
          <mesh
            name="Rock_1"
            castShadow
            receiveShadow
            geometry={nodes.Rock_1.geometry}
            material={materials["Rock_Grey.001"]}
            position={[19.16, 4.02, -2.172]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={88.758}
          />
          <mesh
            name="Plant_Small"
            castShadow
            receiveShadow
            geometry={nodes.Plant_Small.geometry}
            material={materials["Green.001"]}
            position={[40.578, 9.034, -2.55]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={88.758}
          />
          <group
            name="Arrow_Side001"
            position={[20.089, 3.783, -2.227]}
            rotation={[-Math.PI / 2, 0, -0.572]}
            scale={88.758}
          >
            <mesh
              name="Arrow_Side002_1"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side002_1.geometry}
              material={materials["Wood.008"]}
            />
            <mesh
              name="Arrow_Side002_2"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side002_2.geometry}
              material={materials["Main_Dark.001"]}
            />
          </group>
          <mesh
            name="Bridge_Small001"
            castShadow
            receiveShadow
            geometry={nodes.Bridge_Small001.geometry}
            material={materials["Wood.013"]}
            position={[24.085, 4.031, 0.434]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[88.758, 152.305, 88.758]}
          />
          <group
            name="Arrow_Side002"
            position={[62.43, 9.139, -0.186]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={88.758}
          >
            <mesh
              name="Arrow_Side003"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side003.geometry}
              material={materials["Wood.008"]}
            />
            <mesh
              name="Arrow_Side003_1"
              castShadow
              receiveShadow
              geometry={nodes.Arrow_Side003_1.geometry}
              material={materials["Main_Dark.001"]}
            />
          </group>
          <mesh
            name="Plant_Small001"
            castShadow
            receiveShadow
            geometry={nodes.Plant_Small001.geometry}
            material={materials["Green.001"]}
            position={[65.789, 9.296, -2.55]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={88.758}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./models/maps/scene_1/StaticAssets.glb");
