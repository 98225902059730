import React from "react";
import "./loadingScreen.css";
import { Loader, useProgress } from "@react-three/drei";
import { Color } from "three";

export default function LoadingScreen({ started, onStarted }) {
  const { progress } = useProgress();
  if (started) {
    return <></>;
  }
  return (
    <div className={` ${started ? onStarted : "container"}`}>
      <img className="logo" alt="Logo" src="./ui/Logo.svg" />
      <div className="load_container">
        <div
          className="loader"
          style={{
            width: `${progress}%`,
          }}
        >
          <img className="star" alt="Logo" src="./ui/shurican.svg" />
        </div>
      </div>
      <h1
        disabled={progress < 100}
        style={
          progress > 99
            ? {
                color: "#ffd80e",
                hoverColor: "#000000",
              }
            : {
                color: "#3a3e45",
              }
        }
        onClick={onStarted}
        className="click"
      >
        Click to get started
      </h1>
    </div>
  );
}
