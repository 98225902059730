/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Coins.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Coin } from "../../common/Coin";

export function Coins(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/maps/scene_1/Coin.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[11.681, 8.59, -1.922]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[13.435, 8.59, -1.922]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[15.266, 12.902, -3.719]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[16.683, 12.345, -2.219]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[18.208, 11.2, -0.677]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[19.275, 9.791, 0.629]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[5.11, 12.226, -8.413]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[6.864, 12.226, -8.413]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[5.11, 12.226, -6.338]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[6.864, 12.226, -6.338]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[19.975, 7.976, 2.189]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[31.111, 7.946, 2.492]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[31.111, 7.946, -1.635]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[31.111, 7.946, 0.352]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[51.288, 10.089, -4.388]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[51.288, 10.089, -8.514]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[51.288, 10.089, -6.527]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[53.599, 10.032, -4.388]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[53.599, 10.032, -8.514]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[53.599, 10.032, -6.527]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[53.551, 15.273, 0.381]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
        <Coin
          name="Coin"
          castShadow
          receiveShadow
          nodes={nodes}
          materials={materials}
          position={[55.862, 15.216, 0.381]}
          rotation={[Math.PI, 1.407, 0]}
          scale={1}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/maps/scene_1/Coin.glb");
