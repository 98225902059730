import React, { Suspense, useEffect, useRef } from "react";
import { Physics, vec3 } from "@react-three/rapier";
import { Box, Environment, KeyboardControls, Plane } from "@react-three/drei";

import Ecctrl, { EcctrlAnimation } from "ecctrl";
import MainLights from "../components/lights/MainLight";
import Ninja from "../components/characters/Ninja";
import { BlockPlatform } from "../components/maps/scene_1/BlockPlatform";
import { StaticAssets } from "../components/maps/scene_1/StaticAssets";
import { Spikes } from "../components/maps/scene_1/Spikes";
import { Catcher } from "../components/maps/scene_1/Catcher";
import { Saws } from "../components/maps/scene_1/Saws";
import { Flag } from "../components/maps/scene_1/Flag";
import { useGameStateStore } from "../stateManagers/GameStateManager";
import { Coins } from "../components/maps/scene_1/Coins";

export function Scene1() {
  const controllerRigidBody = useRef();
  const gameOver = useGameStateStore((state) => state.gameOver);
  const collectedCoins = useGameStateStore((state) => state.collectedCoins);
  const gameCompleted = useGameStateStore((state) => state.gameCompleted);
  /**
   * Keyboard control preset
   */
  const keyboardMap = [
    { name: "forward", keys: ["ArrowUp", "KeyW"] },
    { name: "backward", keys: ["ArrowDown", "KeyS"] },
    { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
    { name: "rightward", keys: ["ArrowRight", "KeyD"] },
    { name: "jump", keys: ["Space"] },
    { name: "run", keys: ["Shift"] },
    { name: "action1", keys: ["1"] },
    { name: "action2", keys: ["2"] },
    { name: "action3", keys: ["3"] },
    { name: "action4", keys: ["KeyF"] },
  ];

  /**
   * Character url preset
   */
  const characterURL = "./models/characters/Ninja.glb";

  /**
   * Character animation set preset
   */
  const animationSet = {
    idle: "Idle",
    walk: "Walk",
    run: "Run",
    jump: "Jump",
    jumpIdle: "Jump_Idle",
    jumpLand: "Jump_Land",
    fall: "Jump_Idle", // This is for falling from high sky
    action1: "Wave",
    action2: "Punch",
    action3: "HitReact",
    action4: "Weapon",
  };

  useEffect(() => {
    console.log("collectedCoins", collectedCoins);
    if (gameOver || gameCompleted) {
      collectedCoins.forEach((item) => {
        item.visible = true;
      });
      controllerRigidBody.current.setTranslation(vec3({ X: 0, y: 5, z: 0 }));
      controllerRigidBody.current.setLinvel(vec3({ X: 0, y: 0, z: 0 }));
    }
  }, [gameOver, gameCompleted]);

  return (
    <>
      <fog attach="fog" args={["blue", 0, 330]} />
      <color attach="background" args={["#6ed8ff"]} />
      <Environment files="./hdr/little_paris_eiffel_tower_1k.hdr" />
      <MainLights />

      <Physics debug={false}>
        <KeyboardControls map={keyboardMap}>
          <Suspense fallback={null}>
            <Ecctrl
              ref={controllerRigidBody}
              // debug
              animated
              name={"Player"}
              floatHeight={0.1}
              jumpVel={5.76}
            >
              <EcctrlAnimation
                characterURL={characterURL}
                animationSet={animationSet}
              >
                <Ninja />
              </EcctrlAnimation>
            </Ecctrl>
          </Suspense>
        </KeyboardControls>
        <BlockPlatform scale={0.7} />
        <StaticAssets scale={0.7} />
        <Spikes scale={0.7} />
        {/* <PickupItems scale={0.7} /> */}
        <Catcher scale={0.7} />
        <Saws scale={0.7} />
        <Flag scale={0.7} />
        <Coins scale={0.7} />
      </Physics>
    </>
  );
}
