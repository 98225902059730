import "./App.css";
import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Scene1 } from "./scenes/Scene1";
import { Suspense } from "react";
import { Stats } from "@react-three/drei";
import UI from "./components/ui/ui";
import LoadingScreen from "./components/loading screen/loadingScreen";
import { playAudio } from "./stateManagers/GameStateManager";

export default function App() {
  const ref = React.useRef(null);
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (start) {
      playAudio("main_music.mp3", 0.01, true, true);
    }
  }, [start]);

  return (
    <>
      <LoadingScreen started={start} onStarted={() => setStart(true)} />
      <div
        className="ui"
        onClick={() => {
          ref.current.requestPointerLock();
        }}
      >
        <UI />
      </div>
      <Canvas
        shadows
        onPointerDown={(e) => {
          e.target.requestPointerLock();
        }}
        className=""
        ref={ref}
      >
        {/* <Stats /> */}
        <Suspense fallback={null}>
          <Scene1 />
        </Suspense>
      </Canvas>
    </>
  );
}
