/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\BlockPlatform.glb 
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";

export function BlockPlatform(props) {
  const group = useRef();
  const { scene, animations } = useGLTF(
    "./models/maps/scene_1/BlockPlatform.glb"
  );

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, []);
  return (
    <RigidBody type="fixed" colliders="cuboid">
      <primitive castShadow receiveShadow object={scene} {...props} />
    </RigidBody>
  );
}

useGLTF.preload("./models/maps/scene_1/BlockPlatform.glb");
