import React, { Children, useRef } from "react";
import * as THREE from "three";
import { useGLTF, useAnimations, Float } from "@react-three/drei";
import { RigidBody, quat, vec3, euler } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";
import { useGameStateStore } from "../../stateManagers/GameStateManager";

export function Saw({
  pickupItem,
  material,
  children,
  name,
  rotationAxis,
  ...props
}) {
  const rigidBody = useRef();
  const decreaseLives = useGameStateStore((state) => state.decreaseLives);

  useFrame((_, delta) => {
    if (rigidBody.current) {
      const currentRotations = quat(rigidBody.current.rotation());
      const incrementRotation = new THREE.Quaternion().setFromAxisAngle(
        rotationAxis === "x"
          ? new THREE.Vector3(1, 0, 0)
          : rotationAxis === "y"
          ? new THREE.Vector3(0, 1, 0)
          : new THREE.Vector3(0, 0, 1),
        delta * 2
      );
      currentRotations.multiply(incrementRotation);
      rigidBody.current.setNextKinematicRotation(currentRotations);
    }
  });

  const OnCollided = (e) => {
    e.rigidBody.setTranslation(vec3({ X: 0, y: 5, z: 0 }));
    e.rigidBody.setLinvel(vec3({ X: 0, y: 0, z: 0 }));

    decreaseLives();
  };

  return (
    <RigidBody
      ref={rigidBody}
      onCollisionEnter={(e) => OnCollided(e)}
      type="kinematicPosition"
      colliders="cuboid"
      name={name}
      {...props}
    >
      <group name="saw">{children}</group>
    </RigidBody>
  );
}
