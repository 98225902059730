/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Flag.glb 
*/

import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useGameStateStore } from "../../../stateManagers/GameStateManager";

export function Flag(props) {
  const group = useRef();
  const setGameCompleted = useGameStateStore((state) => state.setGameCompleted);
  const gameCompleted = useGameStateStore((state) => state.gameCompleted);
  const [flagCaptured, setFlagCaptured] = useState(false);
  const { nodes, materials } = useGLTF("./models/maps/scene_1/Flag.glb");

  useEffect(() => {
    setFlagCaptured(false);
  }, [gameCompleted]);

  const OnCollided = (e) => {
    if (!flagCaptured && e.rigidBodyObject.name === "Player") {
      setFlagCaptured(true);
      console.log("You won");
      setGameCompleted();
    }
  };
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <RigidBody
          type="fixed"
          colliders="trimesh"
          onCollisionEnter={(e) => OnCollided(e)}
        >
          <group
            name="Goal_Flag"
            position={[83.681, 3.117, 1.575]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={112.899}
          >
            <mesh
              name="Goal_Flag001"
              castShadow
              receiveShadow
              geometry={nodes.Goal_Flag001.geometry}
              material={materials["Metal.013"]}
            />
            <mesh
              name="Goal_Flag001_1"
              castShadow
              receiveShadow
              geometry={nodes.Goal_Flag001_1.geometry}
              material={materials["Flag.003"]}
            />
          </group>
        </RigidBody>
      </group>
    </group>
  );
}

useGLTF.preload("./models/maps/scene_1/Flag.glb");
