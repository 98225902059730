import React, { useEffect } from "react";
import "../ui.css"

export default function Controls() {
  return (
    <div className="hover-content">
      <img  alt="Logo" src="./ui/control.png" className="image" />
    </div>
  );
}
