/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Coin.glb 
*/
import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody, quat, vec3 } from "@react-three/rapier";
import { useGameStateStore } from "../../stateManagers/GameStateManager";
import { useFrame } from "@react-three/fiber";

export function Coin({ nodes, materials, material, children, name, ...props }) {
  const rigidBody = useRef();
  const [collected, setCollected] = useState(false);

  useFrame((_, delta) => {
    if (rigidBody.current) {
      const currentRotations = quat(rigidBody.current.rotation());
      const incrementRotation = new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 1, 0),
        delta * 2
      );
      currentRotations.multiply(incrementRotation);
      rigidBody.current.setNextKinematicRotation(currentRotations);
    }
  });

  const increaseCoins = useGameStateStore((state) => state.increaseCoins);
  const { gameOver, gameCompleted } = useGameStateStore();

  useEffect(() => {
    if (gameOver || gameCompleted) {
      setCollected(false);
    }
  }, [gameOver, gameCompleted]);

  const OnCollided = (e) => {
    if (e.rigidBodyObject.name === "Player" && !collected) {
      setCollected(true);
      e.target.rigidBodyObject.visible = false;
      increaseCoins(e.target.rigidBodyObject);
    }
  };
  return (
    <RigidBody
      ref={rigidBody}
      onIntersectionEnter={(e) => OnCollided(e)}
      type="kinematicPosition"
      wolliders="cuboid"
      name={"Coin"}
      sensor
      {...props}
    >
      <mesh
        name="Coin"
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001_Cylinder021.geometry}
        material={materials.phong1SG}
        rotation={[Math.PI, 1.407, -1.543]}
        scale={33.833}
      />
    </RigidBody>
  );
}

useGLTF.preload("./models/maps/scene_1/Coin.glb");
