import React, { useEffect, useState } from "react";
import "./ui.css";
import { useGameStateStore } from "../../stateManagers/GameStateManager";
import { useTimer } from "react-timer-hook";
import Controls from "./components/controls";
import GameStatusModal from "./components/GameStatusModal";

export default function UI() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);
  const [controls, setControls] = useState(false);
  const { coins, lives, setGameOver, gameOver, gameCompleted } =
    useGameStateStore();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ time, onExpire: () => setGameOver() });

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 300); // 10 minutes timer
    restart(time);
    document.exitPointerLock();
  }, [gameOver, gameCompleted]);

  return (
    <div className="containerUI">
      <div className="nav">
        <div className="stat flex flex-col ">
          <h1 className="coin">Coins {coins} </h1>
          <h1 className="lives">Lives {lives} </h1>
          <h1 className="time">
            Time <span>{minutes}</span>:<span>{seconds}</span>
          </h1>
        </div>
        <div className="button-container">
          <div className="controlButton">
            <h1 className="">?</h1>
            <div className="">
              <Controls />
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        {gameCompleted && <GameStatusModal message={"You Won!"} />}
        {gameOver && <GameStatusModal message={"Game Over"} />}
      </div>
    </div>
  );
}
