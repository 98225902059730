/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\maps\\scene_1\\Spikes.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { RigidBody, vec3 } from "@react-three/rapier";
import { useGameStateStore } from "../../../stateManagers/GameStateManager";

export function Spikes(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/maps/scene_1/Spikes.glb");
  const decreaseLives = useGameStateStore((state) => state.decreaseLives);

  const OnCollided = (e) => {
    e.rigidBody.setTranslation(vec3({ X: 0, y: 5, z: 0 }));
    e.rigidBody.setLinvel(vec3({ X: 0, y: 0, z: 0 }));
    decreaseLives();
  };

  return (
    <RigidBody
      type="fixed"
      colliders="cuboid"
      onCollisionEnter={(e) => OnCollided(e)}
    >
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="Spikes001"
            castShadow
            receiveShadow
            geometry={nodes.Spikes001.geometry}
            material={materials["Metal.015"]}
            position={[9.73, 0.251, 0.056]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[88.758, 88.758, 77.356]}
          />
          <mesh
            name="Spikes005"
            castShadow
            receiveShadow
            geometry={nodes.Spikes005.geometry}
            material={materials["Metal.015"]}
            position={[38.335, 0.251, 0.056]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[88.758, 88.758, 77.356]}
          />
          <mesh
            name="Spikes002"
            castShadow
            receiveShadow
            geometry={nodes.Spikes002.geometry}
            material={materials["Metal.015"]}
            position={[58.873, 4.725, -6.791]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[88.758, 88.758, 77.356]}
          />
          <mesh
            name="Spikes003"
            castShadow
            receiveShadow
            geometry={nodes.Spikes003.geometry}
            material={materials["Metal.015"]}
            position={[45.638, 4.461, -6.791]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[88.758, 88.758, 77.356]}
          />
        </group>
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./models/maps/scene_1/Spikes.glb");
