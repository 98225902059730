import { create } from "zustand";

// export const playAudio = (file, volume = 0.5, callback) => {
//   const audio = new Audio(`./audio/${file}`);
//   audio.volume = volume;
//   if (callback) {
//     audio.addEventListener("ended", callback);
//   }
//   audio.play();
// };
export const playAudio = (file, volume = 0.01, loop = false, play = false) => {
  const audio = new Audio(`./audio/${file}`);
  audio.loop = loop;
  audio.volume = volume;
  if (play) audio.play();
  return audio;
};

export const useGameStateStore = create((set, get) => ({
  coins: 0,
  gameCompleted: false,
  gameOver: false,
  lives: 3,
  collectedCoins: [],
  jumpAudio: playAudio("jump.mp3", 0.4, false, false),
  walkAudio: playAudio("walk.mp3", 0.1, true, false),
  runAudio: playAudio("run.mp3", 0.1, true, false),
  increaseCoins: (coin) => {
    playAudio(`coin.mp3`, 0.5, false, true);
    set((state) => ({
      coins: state.coins + 1,
      collectedCoins: [...state.collectedCoins, coin],
    }));
  },
  decreaseLives: () => {
    playAudio(`die.mp3`, 0.5, false, true);
    set((state) => ({
      gameOver: state.lives <= 1 ? true : false,
      lives: state.lives <= 0 ? 0 : state.lives - 1,
    }));
  },
  resetGameStatus: () => {
    set((state) => ({
      coins: 0,
      gameCompleted: false,
      gameOver: false,
      lives: 3,
      collectedCoins: [],
    }));
  },
  setGameCompleted: () => {
    playAudio("winning.mp3", 0.1, false, true);
    set({ gameCompleted: true });
  },
  setGameOver: () => {
    set((state) => ({
      gameOver: true,
    }));
  },
}));
